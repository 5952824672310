exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contacts-index-tsx": () => import("./../../../src/pages/contacts/index.tsx" /* webpackChunkName: "component---src-pages-contacts-index-tsx" */),
  "component---src-pages-doctors-index-tsx": () => import("./../../../src/pages/doctors/index.tsx" /* webpackChunkName: "component---src-pages-doctors-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-consultation-index-tsx": () => import("./../../../src/pages/services/consultation/index.tsx" /* webpackChunkName: "component---src-pages-services-consultation-index-tsx" */),
  "component---src-pages-services-hygiene-index-tsx": () => import("./../../../src/pages/services/hygiene/index.tsx" /* webpackChunkName: "component---src-pages-services-hygiene-index-tsx" */),
  "component---src-pages-services-implantation-index-tsx": () => import("./../../../src/pages/services/implantation/index.tsx" /* webpackChunkName: "component---src-pages-services-implantation-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-orthodontics-index-tsx": () => import("./../../../src/pages/services/orthodontics/index.tsx" /* webpackChunkName: "component---src-pages-services-orthodontics-index-tsx" */),
  "component---src-pages-services-orthopedics-index-tsx": () => import("./../../../src/pages/services/orthopedics/index.tsx" /* webpackChunkName: "component---src-pages-services-orthopedics-index-tsx" */),
  "component---src-pages-services-surgery-index-tsx": () => import("./../../../src/pages/services/surgery/index.tsx" /* webpackChunkName: "component---src-pages-services-surgery-index-tsx" */),
  "component---src-pages-services-therapy-index-tsx": () => import("./../../../src/pages/services/therapy/index.tsx" /* webpackChunkName: "component---src-pages-services-therapy-index-tsx" */),
  "component---src-pages-services-whitening-index-tsx": () => import("./../../../src/pages/services/whitening/index.tsx" /* webpackChunkName: "component---src-pages-services-whitening-index-tsx" */)
}

